import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Heading from "../components/Heading.component";

const ContactUs = () => {
  const contactDetails = [
    {
      heading: "Times Guaranty Limited",
      cin: "L65920MH1989PLC054398",
      registered: true,
      address: [
        "A-602, Marathon NextGen Innova",
        "GanpatRao Kadam Marg,",
        "Veer Santaji Lane",
        "Lower Parel,",
        "Mumbai-400013",
      ],
      tel: " 91 - 22 - 48818487",
      // fax: " 022 - 22731587",
      email: "newberryaarti@gmail.com",
    },
    {
      heading: "Company Secretary & Compliance officer",
      // dirName: "Ms. Muskaan Tinwala",
      address: [
        "A-602, Marathon NextGen Innova",
        "GanpatRao Kadam Marg,",
        "Veer Santaji Lane",
        "Lower Parel,",
        "Mumbai-400013",
      ],
      tel: " 91 - 22 - 48818487",
      // fax: " 022 - 22731587",
      email: "newberryaarti@gmail.com",
    },
    {
      heading: "Registrar and Transfer Agent",
      address: [
        "MUFG INTIME INDIA PRIVATE LIMITED",
        "(Formerly Known As Link Intime India Pvt Ltd)",
        "C 101, 247 Park,",
        "L B S Marg, Vikhroli West,",
        "Mumbai 400 083",
      ],
      tel: " 91 - 22 - 49186000",
      fax: " 91 - 22 - 49186060",
      email: "rnt.helpdesk@linkintime.co.in",
    },
  ];

  // let maxContactHeight = 0;
  // const equalHeight = () => {
  //   const contactSections = document.querySelectorAll(".contact-quad");

  //   contactSections.forEach((section) => {
  //     const sectionHeight = section.clientHeight;
  //     maxContactHeight =
  //       sectionHeight > maxContactHeight ? sectionHeight : maxContactHeight;
  //   });
  //   contactSections.forEach((section) => {
  //     section.style.height = maxContactHeight + "px";
  //   });
  // };

  // useEffect(() => {
  //   if (maxContactHeight === 0) {
  //     equalHeight();
  //   }
  // }, []);
  
  return (
    <table
      border="0"
      align="center"
      cellPadding="0"
      cellSpacing="0"
      className="tablebg commonWidth"
    >
      <tbody>
        <tr>
          <td>
            <table
              className="commonWidth"
              border="0"
              align="center"
              cellPadding="0"
              cellSpacing="0"
            >
              <tbody>
                {/* Main Content */}
                <tr>
        <td align="left" valign="top" height={"15"}>

        </td>
      </tr>
                <tr>
                  <td align="left" valign="top" className="maincontent">
                    <p className="contact-slogan">
                      Questions concerning your share certificates, address
                      change, consolidation of certificates, lost certificates
                      and related matters for physical shares only, should be
                      addressed to Times Guaranty Limited, directly or through
                      share transfer agent. Address change request in respect of
                      Demat shares should be intimated to the concerned
                      Depository Participant.
                    </p>
                  </td>
                </tr>

                {/* Inner Header */}

                <Heading name={"Contact Us"} />

                {/* Section Contents */}
                <Box component="tr">
                  <Box
                    component="td"
                    align="left"
                    valign="top"
                    className="section-contents"
                  >
                    <Box
                      component="table"
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <Box component="tr">
                          <Box component={"td"}>
                            {contactDetails.map((item, index) => {
                              return (
                                <div
                                  className={`contact-quad caption ${
                                    index === contactDetails.length - 1 &&
                                    "last"
                                  }`}
                                  key={index}
                                >
                                  <span className={`contact-heading ${item.dirName && "noMarginBottom"}`}>
                                    {item?.heading}
                                  </span>

                                  {item?.dirName && (
                                    <span className="dir-name ">
                                      {item?.dirName}
                                    </span>
                                  )}

                                  {item?.cin && (
                                    <div className="contact-details">
                                      <span className="title">
                                        CIN No&nbsp;:
                                      </span>
                                      <span className="value">{item?.cin}</span>
                                    </div>
                                  )}

                                  {item?.registered && (
                                    <span className="dir-name">
                                      Registered Office
                                    </span>
                                  )}

                                  <div className="contact-details">
                                    <span className="title">
                                      Address&nbsp;:
                                    </span>
                                    <span className="value">
                                      {item?.address?.map((line, index) => (
                                        <React.Fragment key={index}>
                                          {line}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                    </span>
                                  </div>

                                  {item?.tel && (
                                    <div className="contact-details">
                                      <span className="title">Tel&nbsp;:</span>
                                      <span className="value">{item?.tel}</span>
                                    </div>
                                  )}
                                  {item?.fax && (
                                    <div className="contact-details">
                                      <span className="title">Fax&nbsp;:</span>
                                      <span className="value">{item?.fax}</span>
                                    </div>
                                  )}

                                  {item?.email && (
                                    <div className="contact-details">
                                      <span className="title">
                                        Email&nbsp;:
                                      </span>
                                      <span className="value email">
                                        <a href={`mailto:${item?.email}`} title="send mail" >
                                          {item?.email}
                                        </a>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Box>
                        </Box>
                      </tbody>
                    </Box>
                  </Box>
                </Box>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
		<td> &nbsp; </td>
		</tr>
    {/* <tr>
		<td> &nbsp; </td>
		</tr> */}
      </tbody>
    </table>
    
  );
};

export default ContactUs;
